

import {mapState, mapGetters, mapActions} from 'vuex'
import TokenIcon from '../../components/TokenIcon.vue'
import config from '../../config.coffee'
import BigNumber from 'bignumber.js'
# import { MessageBox } from 'element-ui'
import {verboseSend, repeat, roundBy} from '../../store/functions.coffee'

import Allowance, { checkAllowance } from '../../components/Allowance.vue'
# import Registration, { checkRegistration } from '../../components/Registration.vue'

# window.BigNumber = BigNumber
# window.roundBy = roundBy

validateMinMaxBase = (min, max, cur, ctx) -> {
  trigger: 'change',
  validator: (rule, value, callback) =>
    if ctx.toBaseUnits(value).lt(ctx[min])
      callback(text: "Min #{ctx.fromBaseUnits(ctx[min])} #{ctx.currentPair[cur]}", value: ctx.fromBaseUnits(ctx[min]))
    else if ctx.toBaseUnits(value).gt(ctx[max])
      callback(text: "Max #{ctx.fromBaseUnits(ctx[max])} #{ctx.currentPair[cur]}", value: ctx.fromBaseUnits(ctx[max]))
    else
      callback()
}

validateMinMaxQuote = (min, max, cur, ctx) -> {
  trigger: 'change',
  validator: (rule, value, callback) =>
    if ctx.toQuoteUnits(value).lt(ctx[min])
      callback(text: "Min #{ctx.fromQuoteUnits(ctx[min])} #{ctx.currentPair[cur]}", value: ctx.fromQuoteUnits(ctx[min]))
    else if ctx.toQuoteUnits(value).gt(ctx[max])
      callback(text: "Max #{ctx.fromQuoteUnits(ctx[max])} #{ctx.currentPair[cur]}", value: ctx.fromQuoteUnits(ctx[max]))
    else
      callback()
}

validateMinMaxPrice = (min, max, cur, ctx) -> {
  trigger: 'change',
  validator: (rule, value, callback) =>
    if ctx.toPrice(value).lt(ctx[min])
      callback(text: "Min #{ctx.fromPrice(ctx[min])} #{ctx.currentPair[cur]}", value: ctx.fromPrice(ctx[min]))
    else if ctx.toPrice(value).gt(ctx[max])
      callback(text: "Max #{ctx.fromPrice(ctx[max])} #{ctx.currentPair[cur]}", value: ctx.fromPrice(ctx[max]))
    else
      callback()
}

export default
  components: {TokenIcon, Allowance}
  data: ->
    tab: "limit"
    processing: false
    limit:
      price: BigNumber(1)
      volume: BigNumber(0)
    market:
      volume: BigNumber(0)
      total: BigNumber(0)
      ids: []
      orders: 0
      lockSlider: false
      full: true
      safe: false
    inputString:
      limitVolume: null
      limitTotal: null
      limitPrice: null
      marketVolume: null
      marketTotal: null
  # watch:
  #   'market.ids': console.info
  computed: {
    model: -> this
    ...mapGetters 'Exchange', [
      'currentPair'
      'userAddress'
      'priceDivisor'
      'buyPrice'
      'minAsk'
      'currency'
      'maxQuoteAsset'
      'minQuoteAsset'
      'maxBaseValue'
      'minBaseValue'
      'asks'
      'orderBook'
      'sellIds'
    ]
    ...mapGetters 'Exchange', ['baseFormat', 'quoteFormat', 'baseParse', 'quoteParse']
    ...mapGetters 'Wallet', ['signer']
    ...mapState 'Exchange', ['contract']
    priceDenominator: -> @priceDivisor
    limitVolume:
      get: -> @fromBaseUnits @limit.volume
      set: (volume) ->
        @limit.volume = roundBy @toBaseUnits(volume), @currency.base.unit
    limitPrice:
      get: -> @fromPrice @limit.price
      set: (price) ->
        @limit.price = (@toPrice price).integerValue()
        @limit.price = @limit.price.plus(1) if @limit.price.isZero()
        # @limit.price = @maxPrice if @limit.price.gt(@maxPrice)
        # @limit.price = @minPrice if @limit.price.lt(@minPrice)
    limitTotal:
      # get: -> @fromSun if @limit.price.isZero() then BigNumber(0) else @limit.total.times(@limit.price).div(@priceDenominator).integerValue()
      get: -> 
        @fromQuoteUnits roundBy(@limit.volume.times(@limit.price).div(@priceDivisor).shiftedBy(@currency.quote.decimals - @currency.base.decimals), @currency.quote.unit)
      set: (total) ->
        total = @toQuoteUnits total
        volume = total.times(@priceDenominator).idiv(@limit.price)
        n = 0
        loop
          break if volume.times(@limit.price).idiv(@priceDenominator).eq total
          if volume.times(@limit.price).idiv(@priceDenominator).gt total
            volume = volume.minus(BigNumber(1))
            break
          else
            volume = volume.plus(BigNumber(1))
        @limit.volume = volume.shiftedBy(@currency.base.decimals - @currency.quote.decimals)
    marketVolume:
      get: -> @fromBaseUnits @market.volume
      set: (volume) ->
        @market.volume = roundBy @toBaseUnits(volume), @currency.base.unit
        volume = @market.volume
        total = BigNumber(0)
        @market.ids = []
        @market.full = true
        for {price, orders} in @asks
          for {asset: vol, value: tot, id} in orders
            if volume.gte(vol)
              volume = volume.minus(vol)
              total = total.plus(tot)
              @market.ids.push id
            else
              total = total.plus(volume.times(price)).integerValue(BigNumber.ROUND_FLOOR)
              volume = BigNumber(0)
              @market.ids.push id
              @market.full = false
              break
          break if volume.isZero()
        @market.orders = @market.ids.length
        @market.total = total
    marketTotal:
      get: -> @fromQuoteUnits @market.total
      set: (total) ->
        @market.total = roundBy @toQuoteUnits(total), @currency.quote.unit
        total = @market.total
        volume = BigNumber(0)
        @market.ids = []
        @market.full = true
        for {price, orders} in @asks
          for {asset: vol, value: tot, id} in orders
            if total.gte(tot)
              total = total.minus(tot)
              volume = volume.plus(vol)
              @market.ids.push id
            else
              volume = volume.plus(total.div(price)).integerValue(BigNumber.ROUND_FLOOR)
              total = BigNumber(0)
              @market.ids.push id
              @market.full = false
              break
          break if total.isZero()
        @market.orders = @market.ids.length
        @market.volume = volume
    sliderMarks: ->
      marks = {}
      marks[0] = '0'
      marks[@orders.length] = @orders.length.toString()
      marks
    orders: -> Object.values(@orderBook.sell)
    minMarketVolume: -> @currency.base.unit
    minMarketTotal: -> @currency.base.unit
    maxMarketVolume: -> @orders.map(({asset}) -> asset).reduce(((a,b) -> a.plus(b)), BigNumber(0))
    maxMarketTotal: -> @orders.map(({value}) -> value).reduce(((a,b) -> a.plus(b)), BigNumber(0))
    maxLimitTotal: -> @maxQuoteAsset
    minLimitTotal: -> @minQuoteAsset
    maxLimitVolume: -> @maxBaseValue
    minLimitVolume: -> @minBaseValue
    minPrice: -> BigNumber(@buyPrice.min)
    maxPrice: ->
      if @minAsk.isZero() or @buyPrice.max.lt(@minAsk)
        @buyPrice.max
      else
        @minAsk.minus(1)
    stepPrice: -> BigNumber(1).div(@priceDivisor)
    stepBase: -> @currency.base.unit
    stepQuote: -> @currency.quote.unit

    rules: ->
      limitPrice: [
        validateMinMaxPrice('minPrice', 'maxPrice', 1, this)
      ]
      limitVolume: [
        validateMinMaxBase('minLimitVolume', 'maxLimitVolume', 0, this)
      ]
      limitTotal: [
        validateMinMaxQuote('minLimitTotal', 'maxLimitTotal', 1, this)
      ]
      marketVolume: [
        validateMinMaxBase('minMarketVolume', 'maxMarketVolume', 0, this)
      ]
      marketTotal: [
        validateMinMaxQuote('minMarketTotal', 'maxMarketTotal', 1, this)
      ]
      # , {
      #     trigger: 'blur',
      #     validator: (rule, value, callback) =>
      #       # tronWeb.trx.getAccount(@userAddress).then ({balance}) =>
      #       #   if value.gt BigNumber(balance)
      #       #     callback(new Error("Недостаточно TRX на балансе"))
      #       #   else
      #       #     callback()
      #       # .catch (e) =>
      #       #   console.error(e)
      #       #   callback(new Error("Не удалось проверить баланс"))
      #   }
      # abcPrice: [
      #   {
      #     trigger: 'blur',
      #     validator: (rule, value, callback) =>
      #       @hyhoContract.balanceOf(@userAddress).call().then (balance) =>
      #         # console.info("HYHO:", tronWeb.fromSun(BigNumber(balance).toString()))
      #         if value.gt BigNumber(balance)
      #           callback(new Error("Недостаточно HYHO на балансе"))
      #         else
      #           callback()
      #       .catch (e) =>
      #         console.error(e)
      #         callback(new Error("Не удалось проверить баланс"))
      #   }, {
      #     trigger: 'change',
      #     validator: (rule, value, callback) =>
      #       if value.lt BigNumber(@minPriceHYHO)
      #         callback(new Error("Минимальное значение #{tronWeb.fromSun(@minPriceHYHO)} HYHO"))
      #       else
      #         callback()
      #   }
      # ]
  }
  mounted: ->
    # window.el = @$el.getElementsByTagName('input')[0]
    for el in @$el.getElementsByTagName('input')
      el.addEventListener "keydown", (event) ->
        event.preventDefault() if event.key == '-' or event.key == '+'

    @setPrice()

    # @hyhoContract = @$store.dispatch('contracts/load', config.contracts.TRC20.HYHO)
  methods: {
    # ... mapActions 'Exchange', ['baseFormat', 'quoteFormat', 'baseParse', 'quoteParse']
    blur: (field) ->
      @market.lockSlider = false
      @inputString[field] = null
    input: (field, val) ->
      @market.lockSlider = true
      @inputString[field] = val
      this[field] = val if !!val
    # fromSun: (value) -> +tronWeb.fromSun value
    # toSun: (value) -> BigNumber tronWeb.toSun value
    fromBaseUnits: (value) -> +@baseFormat value.toLocaleString('en', useGrouping: false)
    fromQuoteUnits: (value) -> +@quoteFormat value.toLocaleString('en', useGrouping: false)
    toBaseUnits: (value) -> BigNumber @baseParse(value).toString()
    toQuoteUnits: (value) -> BigNumber @quoteParse(value).toString()
    fromPrice: (value) -> value.div(@priceDenominator).toNumber()
    toPrice: (value) -> BigNumber(value).times(@priceDenominator)
    setPrice: ->
      @limit.price = @minPrice
    limitBuy: ->
      @$refs.limitBuy.validate (valid) =>
        if valid
          @processing = true


          price = @limit.price.toString()
          amount = @limit.volume.toString()
          total = (@limit.volume.times(@limit.price).div(@priceDenominator).shiftedBy(@currency.quote.decimals - @currency.base.decimals)).integerValue().toString()

          if @currentPair[1] == 'BNB'
            verboseSend @contract.connect(@signer).limitBuy(0, amount, value: total)
            .then (msg) =>
              msg.message = 'The transaction has been sent. The information on the page will be updated in a few seconds.'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
          else
            if await checkAllowance(total, await @contract.quoteCurrency(), @contract.address
              'Allow the exchange\'s contract to charge from your address'
            )
              verboseSend @contract.connect(@signer).limitBuy(total, amount)
              .then (msg) =>
                msg.message = 'The transaction has been sent. The information on the page will be updated in a few seconds.'
                msg.duration = 3000
                @$emit 'reload', silent: true
              .finally =>
                @processing = false
            else
              @processing = false
        else
          return false
      return

    marketBuy: ->
      @$refs.marketBuy.validate (valid) =>
        if valid
          @processing = true


          feeLimit = 500
          ids = @sellIds
          total = @market.total.toString()
          # console.info total, ids, ids.length
          if @currentPair[1] == 'BNB'
            verboseSend @contract.connect(@signer).marketBuy(total, ids, value: total)
            .then (msg) =>
              msg.message = 'The transaction has been sent. The information on the page will be updated in a few seconds.'
              msg.duration = 3000
              @$emit 'reload', silent: true
            .finally =>
              @processing = false
          else
            if await checkAllowance(total, await @contract.quoteCurrency(), @contract.address
              'Allow the exchange\'s contract to charge from your address'
            )
              verboseSend @contract.connect(@signer).marketBuy(total, ids)
              .then (msg) =>
                msg.message = 'The transaction has been sent. The information on the page will be updated in a few seconds.'
                msg.duration = 3000
                @$emit 'reload', silent: true
              .finally =>
                @processing = false
            else
              @processing = false


        else
          return false
      return
    takeOrders: (n) ->
      unless @market.lockSlider
        @market.orders = n
        total = BigNumber(0)
        volume = BigNumber(0)
        @market.ids = []
        @market.full = true
        if n > 0
          for {price, orders} in @asks
            for {asset: vol, value: tot, id} in orders
              total = total.plus(tot)
              volume = volume.plus(vol)
              @market.ids.push id
              n -= 1;
              break if n == 0
            break if n == 0
        @market.total = total
        @market.volume = volume
      else
        @market.orders = n
  }

