
import config from '../config.coffee'
import TokenIcon from './TokenIcon.vue'
import BigNumber from 'bignumber.js'
import {mapState, mapGetters, mapActions} from 'vuex'
import {repeat} from '../store/functions.coffee'

_checkAllowance = null

export checkAllowance = -> _checkAllowance(...arguments)

export default
  components: {TokenIcon}
  data: ->
    waiting: false
    allowance: null
    minValue: null
    title: "Approval required"
    visible: false
    contractName: ""
    tokenSymbol: "HYHO"
    description: ""
    decimals: 18
  computed: {
    model: () -> this
    ...mapState 'Wallet', userAddress: (state) -> state.account.address
    ...mapGetters 'Wallet', ['signer']
    rules: ->
      allowance: [
        trigger: 'change',
        validator: (rule, value, callback) =>
          callback()
          # console.info value
          # if BigNumber(tronWeb.toSun value).lt BigNumber(tronWeb.toSun @minValue)
          #   callback(new Error("Must allow at least #{@minValue} #{@tokenSymbol}"))
          # else
          #   callback()
      ]
  }
  mounted: ->
    _checkAllowance = (value, tokenAddress, contractAddress, description) =>
      @tokenContract = await @$store.dispatch('contracts/loadToken', tokenAddress)
      allowance = await @tokenContract.allowance(@userAddress, contractAddress)
      allowance = if allowance.remaining
        BigNumber(allowance.remaining.toString())
      else
        BigNumber(allowance.toString())
      @decimals = await @tokenContract.decimals()
      # console.info "allowance", allowance.toString()
      # console.info(tronWeb.fromSun(allowance.toString()), symbol)
      @allowance = +ethers.utils.formatUnits(value.toString(), @decimals)
      @minValue = @allowance
      @tokenSymbol = await @tokenContract.symbol()
      @contractAddress = contractAddress; @description = description
      # return promise
      new Promise (resolve, reject) =>
        @resolve = resolve; @reject = reject
        # console.info(value.toString())
        if allowance.gte value
          resolve(true)
        else
          @visible = true
  methods:
    allow: (event) ->
      # event.preventDefault() if event
      @waiting = true
      @$refs.form.validate (valid) =>
        if valid
          # p @signer
          @tokenContract.connect(@signer).approve(@contractAddress, ethers.utils.parseUnits(@allowance.toString(), @decimals)).then (tx) => 
            tx.wait().then (rx) => @resolve(true)
          .catch (e) =>
            console.error(e)
            @resolve(false)
          .finally =>
            @waiting = false
            @visible = false
        else
          @waiting = false
    cancel: ->
      @visible = false
      @resolve(false)


