# import deployed from "../../contracts/bsc/deployed.json"
export network = 'mainnet'
# export network = 'localhost'
# export FACTORY_ADDRESS = deployed[network].factory.address
# export MULTICALL_ADDRESS = deployed[network].multicall.address

export FACTORY_ADDRESS = '0x7EB8D270256682742368f6a606E98a214CbF9b2f'
export MULTICALL_ADDRESS = '0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D'

export default
  control:
    permissions:
      'Exchange': [
        '0xBA46C6f961a919b28bc32B5C20cA0190C9388A9F'
        '0x3478854047377531366Ce4344b3E6E0634d349Ae'
        '0x2Be4789b44Dfe7FE652544f15409F93e98cf5506'
      ]
    map:
      'Exchange': 'Exchange'