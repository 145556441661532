import { render, staticRenderFns } from "./Buy.vue?vue&type=template&id=22f37fb5&scoped=true&lang=pug&"
import script from "./Buy.vue?vue&type=script&lang=coffee&"
export * from "./Buy.vue?vue&type=script&lang=coffee&"
import style0 from "./Buy.vue?vue&type=style&index=0&id=22f37fb5&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f37fb5",
  null
  
)

export default component.exports