import config from '../config.coffee'
# import contracts from '../contracts/nile'
# import contracts from '../contracts/mainnet'

import {
  FACTORY_ADDRESS
  MULTICALL_ADDRESS
} from '../config.coffee'
import FactoryABI from '../abi/OrderBookFactory.sol/OrderBookFactory.json'
import OrderBookABI from '../abi/OrderBook.sol/OrderBook.json'
import MulticallABI from '../abi/Multicall.sol/Multicall2.json'
import ERC20ABI from '../abi/Token.sol/Token.json'

import { Multicall } from 'ethereum-multicall'  

{Contract} = ethers

export default
  namespaced: true
  state: =>
    cached: {}
    multicall: null
  mutations:
    cache: (state, {address, contract}) -> state.cached[address] = contract
    multicall: (state, {provider}) ->
      state.multicall = new Multicall
        ethersProvider: provider
        tryAggregate: true
        multicallCustomContractAddress: MULTICALL_ADDRESS
  actions:
    initialize: ({commit, dispatch, rootState: Wallet: {defaultProvider}}) ->
      commit 'multicall', provider: defaultProvider
      await Promise.all [
        dispatch 'load', FACTORY_ADDRESS
        dispatch 'load', MULTICALL_ADDRESS
      ]
    load: ({commit, state, rootState, getters}, address) ->
      if state.cached[address]
        state.cached[address]
      else
        abi = switch
          when FACTORY_ADDRESS.toLowerCase() == address.toLowerCase() then FactoryABI
          when MULTICALL_ADDRESS.toLowerCase() == address.toLowerCase() then MulticallABI
          else OrderBookABI
        provider = rootState.Wallet.defaultProvider
        contract = new Contract(address, abi, provider)
        commit 'cache', {address, contract}
        contract
    loadToken: ({commit, state, rootState, rootGetters}, address) ->
      # address = rootGetters["Events/tokens"][symbol.toUpperCase()]
      # console.info address
      # address = null
      if state.cached[address]
        state.cached[address]
      else
        abi = ERC20ABI
        provider = rootState.Wallet.defaultProvider
        contract = new Contract(address, abi, provider)
        commit 'cache', {address, contract}
        contract
  getters:
    factory: ({cached}) -> cached[FACTORY_ADDRESS]
    multicall: ({cached}) -> cached[MULTICALL_ADDRESS]
