import {singularMutations} from './functions.coffee'
import {network} from '../config.coffee'

Web3Modal = window.Web3Modal.default;
WalletConnectProvider = window.WalletConnectProvider.default;
{
  # Contract
  providers: {JsonRpcProvider}
  # BigNumber
} = ethers

list = [
  'https://bsc-dataseed2.binance.org/'
  'https://bsc-dataseed3.binance.org/'
  'https://bsc-dataseed4.binance.org/'
  'https://bsc-dataseed1.defibit.io/'
  'https://bsc-dataseed2.defibit.io/'
  'https://bsc-dataseed3.defibit.io/'
  'https://bsc-dataseed4.defibit.io/'
  'https://bsc-dataseed1.ninicoin.io/'
  'https://bsc-dataseed2.ninicoin.io/'
  'https://bsc-dataseed3.ninicoin.io/'
  'https://bsc-dataseed4.ninicoin.io/'
]

import {sample} from 'lodash'

providerOptions = {
  walletconnect:
    package: WalletConnectProvider
    options:
      rpc:
        # 56: 'https://bsc-dataseed1.defibit.io/'
        56: 'https://dark-practical-leaf.bsc.quiknode.pro/af709c15490698df1b04efb111652b9d62c845fb/'
      network: "binance"
      chainId: 56
}

export default
  namespaced: true
  state: =>
    defaultProvider: null
    provider: null
    web3Modal: null
    web3ModalInstance: null
    account:
      address: false
  mutations: {
    ...singularMutations [
      'defaultProvider'
      'provider'
      'web3ModalInstance'
      'web3Modal'
      'account'
    ]
  }
  actions:
    initialize: ({commit, dispatch}) ->
      commit 'defaultProvider', switch network
        when 'localhost'
          new JsonRpcProvider "http://127.0.0.1:8545/", chainId: 31337
        when 'testnet'
          new JsonRpcProvider "https://data-seed-prebsc-1-s1.binance.org:8545", chainId: 97
        when 'mainnet'
          # new JsonRpcProvider "https://bsc-dataseed1.defibit.io/", chainId: 56
          # new JsonRpcProvider "https://dark-practical-leaf.bsc.quiknode.pro/af709c15490698df1b04efb111652b9d62c845fb/", chainId: 56
          # new JsonRpcProvider 'https://boldest-dark-pine.bsc.quiknode.pro/c1fcab2b1a91dba82f453066f1a3ea419d1495d6/', chainId: 56
          new JsonRpcProvider 'https://rpc.ankr.com/bsc', chainId: 56
          # new JsonRpcProvider sample(list), chainId: 56
          
      dispatch 'connect' if sessionStorage.connected
    connect: ({commit}) ->
      # network = 'mainnet'
      cacheProvider = true
      web3Modal = new Web3Modal {network, cacheProvider, providerOptions}
      commit 'web3Modal', web3Modal
      instance = await web3Modal.connect()
      commit 'web3ModalInstance', instance
      provider = new ethers.providers.Web3Provider(instance)
      commit 'provider', provider
      signer = provider.getSigner()
      signer.getAddress().then (address) -> commit 'account', {address}
      # dispatch 'subscribe'  
      sessionStorage.connected = true

    disconnect: ({state: {web3Modal}, commit}) ->
      web3Modal.clearCachedProvider()
      commit 'provider', null
      commit 'account', {address: false}
    # subscribe: ({state: {provider}}) ->
    #   provider.on "accountsChanged", (accounts) -> console.info accounts
    #   provider.on "chainChanged", (chainId) -> console.info chainId
    #   provider.on "connect", (info) -> console.info info
    #   provider.on "disconnect", (error) -> console.info error      
  getters:
    provider: ({defaultProvider, provider}) -> provider || defaultProvider
    signer: ({provider}) -> provider.getSigner()
    shortAddress: ({account: {address}}) -> address[..5]+".."+address[-4..]
  
